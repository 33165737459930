// 웹뷰
export const FILE_UPLOAD = '/fileUpload';
export const LOBBY_FILE = '/lobbyFile';
export const PROFILE_UPLOAD = '/profileUpload';

//메인
export const INQUIRY_PAGE = '/contact/inquiry';

//어드민
export const ADMIN_LOGINPAGE = '/mxsytad';
export const ADMIN_BASIC = '/admin';
export const ADMIN_ALL = '/admin/*';

export const ADMIN_REGISTCOMPANY = '/registCompany';
export const ADMIN_REGISTCOMPANY_F = ADMIN_BASIC + '/registCompany';

export const ADMIN_REGISTWAITLIST = '/registWaitList';
export const ADMIN_REGISTWAITLIST_F = ADMIN_BASIC + '/registWaitList';

export const ADMIN_MANAGECOMPANY = '/manageCompany';
export const ADMIN_MANAGECOMPANY_F = ADMIN_BASIC + '/manageCompany';

export const ADMIN_SPACE = '/space';
export const ADMIN_SPACE_F = ADMIN_BASIC + '/space';

export const ADMIN_NOTICE_EDIT = '/notice/edit/:id';
export const ADMIN_MANAGECOMPANY_EDIT = '/manageCompany/edit/:id';
export const ADMIN_MANAGECOMPANY_USER = '/manageCompany/user/:id';
export const ADMIN_MANAGECOMPANY_GUEST = '/manageGuest/list/:id';

export const ADMIN_NOTICE_CREATE = '/notice/create';
export const ADMIN_NOTICE_CREATE_F = ADMIN_BASIC + '/notice/create';
export const ADMIN_NOTICE = '/notice';
export const ADMIN_NOTICE_F = ADMIN_BASIC + '/notice';

export const ADMIN_MANAGEGUEST = '/manageGuest';
export const ADMIN_MANAGEGUEST_F = ADMIN_BASIC + '/manageGuest';

export const ADMIN_DASHBOARD = '/dashboard';
export const ADMIN_DASHBOARD_F = ADMIN_BASIC + '/dashboard';

export const ADMIN_MAPLIST = '/maplist';
export const ADMIN_MAPLIST_F = ADMIN_BASIC + '/maplist';

export const ADMIN_MAPSETTING = '/mapsetting';
export const ADMIN_MAPSETTING_F = ADMIN_BASIC + '/mapsetting';

export const ADMIN_LOBBYSETTING = '/lobbysetting';
export const ADMIN_LOBBYSETTING_F = ADMIN_BASIC + '/lobbysetting';

export const ADMIN_APPKEY = '/appkey';
export const ADMIN_APPKEY_F = ADMIN_BASIC + '/appkey';

// 유저
export const USER_LOGINPAGE = '/login';
export const USER_BASIC = '/users/';
export const USER_ALL = '/users/*';

export const USER_USERLIST = '/userList';
export const USER_USERLIST_F = '/users/userList';

export const USER_GROUPLIST = '/groupList';
export const USER_GROUPLIST_F = '/users/groupList';

export const USER_APPLINK = '/appLink';
export const USER_APPLINK_F = '/users/appLink';

export const JOIN_REQUESTPAGE = '/join_request';

export const USER_MODELFILEMANAGE = '/modelFileManage';
export const USER_MODELFILEMANAGE_F = '/users/modelFileManage';

//LGES
export const USER_MAPVIDEO_MANAGING_F = '/users/videoManaging';

//404
export const FORBIDDEN_PAGE = '/forbiddenPage';

//guide
export const MXSPACE_GUIDE = '/MXSpaceSetupGuide/:lang';
export const MXSPACE_GUIDE_all = '/MXSpaceSetupGuide/*';

//download
export const MXSPACE_DOWNLOAD = '/MXSpaceDownload';

// 업체별 앱 다운로드 페이지 ex. 한기대(koreaTech)
export const APPLINK = '/applink';
export const APPLINK_F = '/applink/*';
export const APPLINK_COMPANY = '/applink/:company';
//----------------------------------------- 임시
export const GALLERY_FILE_UPLOAD = "/gallery"