import "../../utils/utils.css";
import "./css/LobbyFileManage.css";

import CloseIcon from '@mui/icons-material/Close';
import { Button, Collapse, Input, Modal, Select, Tabs, } from "antd";
import { useEffect, useState } from "react";
import { userObject, closeWindow, InappModalConfirm2 } from './FileManageCommon';
import loadingImg from './images/file_loading.png';
import { FileManageEmptySearchTemp } from "./FileManageEmptySearch";
import Table, { ColumnsType } from "antd/lib/table";

import bag from './images/gallery/bag.png';
import bag1 from './images/gallery/bag1.png';
import bag2 from './images/gallery/bag2.png';
import bag3 from './images/gallery/bag3.png';
import bag4 from './images/gallery/bag4.png';
import keyring from './images/gallery/keyring.png';
import keyring1 from './images/gallery/keyring1.png';
import keyring2 from './images/gallery/keyring2.png';
import keyring3 from './images/gallery/keyring3.png';
import keyring4 from './images/gallery/keyring4.png';
import mug from './images/gallery/mug.png';
import mug1 from './images/gallery/mug1.png';
import mug2 from './images/gallery/mug2.png';
import mug3 from './images/gallery/mug3.png';
import mug4 from './images/gallery/mug4.png';
import pen from './images/gallery/pen.png';
import pen1 from './images/gallery/pen1.png';
import pen2 from './images/gallery/pen2.png';
import pen3 from './images/gallery/pen3.png';
import pen4 from './images/gallery/pen4.png';
import phocase from './images/gallery/phocase.png';
import phocase1 from './images/gallery/phocase1.png';
import phocase2 from './images/gallery/phocase2.png';
import phocase3 from './images/gallery/phocase3.png';
import phocase4 from './images/gallery/phocase4.png';

import { ArrowLeftOutlined, MinusOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";

import type { MenuProps } from 'antd';
import { Menu } from 'antd';


import cart from './images/btn_cart.png';

const { Option } = Select;
const { TextArea } = Input;
const deliveryFee = 3000;
const sortingInArr = ["asc", "desc"];
const sortingByArr = ["fileName", "fileType", "price", "fileSrc"];
const tempData: tempType[] = [
	{
		fileUuid: 0, tab: 2, fileName: "Two Cosmos", fileType: "머그컵", fileTypeDesc:"와 아름다운 곡선이 어우러진 타원머그컵 ", price: 32000,
		fileSrc: mug, fileSrc1: mug1, fileSrc2: mug2, fileSrc3: mug3, fileSrc4: mug4, fileDetail: ["Size: 120 x 140mm", "Color: White", " Material: Ceramics"]
	}
	, {
		fileUuid: 1, tab: 4, fileName: "C-508", fileType: "핸드폰케이스", fileTypeDesc:" 작품을 발췌하여 제작한 핸드폰 케이스", price: 40000,
		fileSrc: phocase, fileSrc1: phocase1, fileSrc2: phocase2, fileSrc3: phocase3, fileSrc4: phocase4, fileDetail: ["Size: 75.8(W) x 151(H) x 10.5(D)mm", "Weight: 15g", "Metarial: Polycarbonate"]
	}
	, {
		fileUuid: 2, tab: 3, fileName: "World-Cosmos", fileType: "가방", fileTypeDesc:" 작품을 생생하게 구현한 캔버스 가방 ", price: 48000,
		fileSrc: bag, fileSrc1: bag1, fileSrc2: bag2, fileSrc3: bag3, fileSrc4: bag4, fileDetail: ["Size: 380 x 430mm", " Material: Canvas"]
	}
	, {
		fileUuid: 3, tab: 5, fileName: "생성의 우주2", fileType: "볼펜-블랙", fileTypeDesc:" 작품을 발췌하여 제작한 볼펜", price: 4000,
		fileSrc: pen, fileSrc1: pen1, fileSrc2: pen2, fileSrc3: pen3, fileSrc4: pen4, fileDetail: ["Size: 148mm", "Point size: 0.7mm", "Pen color: Black"]
	}
	, {
		fileUuid: 4, tab: 4, fileName: "Cosmos Cubism", fileType: "키링", fileTypeDesc:" 작품을 발췌하여 제작한 키링", price: 25000,
		fileSrc: keyring, fileSrc1: keyring1, fileSrc2: keyring2, fileSrc3: keyring3, fileSrc4: keyring4, fileDetail: ["Size: 25 x 130 mm", "Meterial: PVC, PU"]
	}
]
export interface tempType {
	tab: number, 
	fileUuid: number,
	fileName: string,
	fileType: string,
	fileDetail: string[],
	fileTypeDesc : string, 
	fileSrc: any,
	fileSrc1: any,
	fileSrc2: any,
	fileSrc3: any,
	fileSrc4: any,
	price?: number,
}
export default function FileMange_gallery() {
	const [userUuid, setUserUuid] = useState<userObject>({ "user_uuid": "", "isModel": false, "modelType": 0, "lang": "kr" });
	const [fileObj, setFileObj] = useState<tempType | null>(null);
	const [selectedPage, setSelectedPage] = useState(0); // 0 - 상품목록, 1 - 상품상세, 2 - 주문상세
	const [searchText, setSearchText] = useState("");
	const [showingTabNum, setShowingTabNum] = useState(100);
	const [reload, setReload] = useState(false); // 파일 처리 감지용

	const [showingType, setShowingType] = useState(0); // 0 - 큰 이미지, 1- 자세히
	const [sortingBy, setSortingBy] = useState(sortingByArr[0]);
	const [sortingIn, setSortingIn] = useState(sortingInArr[0]);
	const [showingModal, setShowingModal] = useState(false); //

	const [selectedPrice, setSelectedPrice] = useState(0);
	const [count, setCount] = useState(1);
	const [payPossible, setPayPossible] = useState(false);
	useEffect(() => {
		setPayPossible(false)
	}, [selectedPage])
	useEffect(() => {
		var metaTags2 = document.createElement("meta");
		metaTags2.setAttribute("http-equiv", "Expires");
		metaTags2.setAttribute("content", "0");
		document.getElementsByTagName('head')[0].insertBefore(metaTags2, document.getElementsByTagName('head')[0].firstChild)
		var metaTags1 = document.createElement("meta");
		metaTags1.setAttribute("http-equiv", "Cache-Control");
		metaTags1.setAttribute("content", "no-cache, no-store, must-revalidate");
		document.getElementsByTagName('head')[0].insertBefore(metaTags1, document.getElementsByTagName('head')[0].firstChild)
	}, []);

	const setTapNumber = (tapNumber: number) => {
		setUserUuid({ ...userUuid, "modelType": tapNumber })
	}
	useEffect(() => {
	}, [sortingBy, sortingIn])
	const onGoBack = () => {
		if(selectedPage === 1) setCount(1);
		setSelectedPage(selectedPage - 1)
	}
	const onPayHandler = () => {
		Modal.info({
			icon : null,
			className : "inappModal",
			content: <div style={{padding:'10px', whiteSpace:"pre-wrap"}}>
				<div>결제 시스템을 준비중입니다.</div>
				<div>곧 더 편리하게 이용하실 수 있습니다.</div>
			</div>,
			onOk() {setSelectedPage(0);setCount(1);},
			centered:true
		});
	}
	return (
		<div className={`tempAroundBorder`}>
			<div className={`fullLayout`}>
				<div className={`categoryLayoutG`}>
					<div className={`categoryContentG`}>
						<TabComponent tapNumber={userUuid.modelType} setTapNumber={setTapNumber} showingTabNum={showingTabNum}
							setFileObj={setFileObj} setSelectedPage={setSelectedPage} setCount={setCount}/>
					</div>
				</div>
				<div className={`mainLayoutTemp`}>
					<div className={`headerLayout`}>
						{fileObj !== null && selectedPage > 0 ?
							<div className={`searchLayoutB`} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', gap: '20px' }}>
								<div className={`searchIconLayout`}><ArrowLeftOutlined style={{ color: 'black', fontWeight: 700 }} onClick={() => onGoBack()} /></div>
								<div style={{ fontSize: "20px", fontWeight: 700 }}> {selectedPage === 1 ? '상품 상세' : '주문서'}</div>
							</div>
							:
							<div>
								<div className={`sortLayoutL sortLayoutLG`}>
									<div className={showingType ? `sortByLayout sortByLayoutG` : `sortByLayoutClicked sortByLayoutG`} onClick={() => { setShowingType(0) }}>
										<img className={showingType ? `sortBy1` : `sortBy1Clicked`} alt="sortBy1" />
										<div className={showingType ? `sortByText` : `sortByTextClicked`}>이미지</div>
									</div>
									<div className={!showingType ? `sortByLayout sortByLayoutG` : `sortByLayoutClicked sortByLayoutG`} onClick={() => { setShowingType(1) }}>
										<img className={showingType ? `sortBy2` : `sortBy2Clicked`} alt="sortBy2" />
										<div className={!showingType ? `sortByText` : `sortByTextClicked`}>자세히</div>
									</div>
								</div>
							</div>}
						<div className={`headerButtons`}>
							<CloseIcon className={`headerIconLayout`}
								onClick={() => {
									if (fileObj) {
										InappModalConfirm2(<div>팝업 창을 닫습니까?</div>, () => closeWindow(""), () => { console.log("cancel closing") }, "primary")
									}
									else { closeWindow("") }
								}}
							/>
							<img src={cart} width="38px " />
						</div>
					</div>
					{fileObj !== null && selectedPage > 0 ?
						<>
							{selectedPage === 1 ? <ProductDetailPage fileObj={fileObj} setSelectedPrice={setSelectedPrice} selectedPrice={selectedPrice} count={count} setCount={setCount} />
								: selectedPage === 2 ? <OrderDetailPage fileObj={fileObj} setSelectedPrice={setSelectedPrice} selectedPrice={selectedPrice} count={count} setPayPossible={setPayPossible}/>
									: <div></div>
							}
						</>
						:
						<>
							<div className={`bodyLayout`}>
								<CardListComponent
									searchText={searchText}
									tapNumber={userUuid.modelType}
									setFileObj={setFileObj}
									setTapNumber={setTapNumber}
									setUserUuid={setUserUuid}
									setShowingTabNum={setShowingTabNum}
									reload={reload}
									userUuid={userUuid}
									sortingIn={sortingIn}
									sortingBy={sortingBy}
									showingType={showingType}
									tempData={tempData}
									setSelectedPage={setSelectedPage}
								/>
							</div>
						</>
					}
					<div className={`footerLayoutG`}>
						<div className={`footerTitle`}><b>판매기간</b> : 2024년 12월 28일 ~ 31일 까지 4일간 판매</div>
						{fileObj !== null && selectedPage === 1 && <div className={`footerBtn`}>
							<Button style={{ width: "154px", height: "46px", borderRadius: "30px", fontSize: "16px", marginRight: "20px" }}
								//disabled={true}
								type="primary"
								className={`footerBtn2`}
								onClick={() => setSelectedPage(2)}
							>
								구매하기
							</Button>
							<Button style={{ width: "154px", height: "46px", borderRadius: "30px", fontSize: "16px" }}
								disabled={true}
								type="primary"
								className={`footerBtn2`}
							>
								장바구니
							</Button>
						</div>}
						{fileObj !== null && selectedPage === 2 && <div className={`footerBtn`}>
							<Button style={{ width: "154px", height: "46px", borderRadius: "30px", fontSize: "16px", marginRight: "20px" }}
								disabled={!payPossible}
								type="primary"
								className={`footerBtn2`}
								onClick={() => onPayHandler()}>
								결제하기
							</Button>
						</div>}
					</div>
				</div>
			</div>
		</div>
	)
}


type MenuItem = Required<MenuProps>['items'][number];
const items: MenuItem[] = [
	{
		key: 'sub1', label: '오경환 작가',
		icon: null,
		children: [
			{ key: '0', label: 'All' },
			{ key: '1', label: '액자' },
			{ key: '2', label: '컵' },
			{ key: '3', label: '패브릭' },
			{ key: '4', label: '액세서리' },
			{ key: '5', label: '필기도구' },
		],
	}
]

const TabComponent = (props: any) => {
	let { tapNumber, setCount } = props;
	const [seletedKeyData, setSeletedKeyData] = useState('sub1');
	const onClickHandler: MenuProps['onClick'] = (e) => {
		console.log('click ', e);
		props.setTapNumber(Number(e.key));
		props.setFileObj(null);
		props.setSelectedPage(0);
		setCount(1);
	};
	return (
		<div>
			<div className="galleryTabHeader">작가 선택</div>
			<Menu
				defaultOpenKeys={["sub1"]}
				defaultSelectedKeys={['0']}   
				mode="inline"
				items={items}
				onClick={onClickHandler}
				className="galleryMenu"
				expandIcon={null}
			/>
		</div>
	)
}


const CardListComponent = (props: any) => {
	const [indexCard, setIndexCard] = useState(-1);
	const [fileList, setFileList] = useState<tempType[]>(tempData);
	const [fileListSaved, setFileListSaved] = useState<tempType[]>(tempData);
	const [fileListSorted, setFileListSorted] = useState<tempType[]>(tempData);
	const [userUuid, setUserUuid] = useState<userObject>(props.userUuid);

	const columns: ColumnsType<tempType> = [
		{
			title: '이름',
			dataIndex: 'fileName',
			align: 'left' as 'left',
			render(value, record, index) {
				return (
					<div style={{ display: "flex", alignItems: "center" }}>
						<img className={`cardImageInTable`} src={record.fileSrc} />
						<div style={{ textAlign: "center" }}>{value + " " + record.fileType}</div>
					</div>

				)
			},
		},
		{
			title: '금액',
			dataIndex: 'price',
			width: '27 %',
			align: 'center' as 'center',
			render(value, record, index) {
				return (<div style={{ width: "100%", textAlign: "center" }}>{value.toLocaleString('ko-KR')}원</div>)
			},
		}
	];

	useEffect(() => { // vuplex
		if (window.vuplex) {
			window.vuplex.addEventListener('message', (event: { data: any; }) => {
				let json: userObject = JSON.parse(event.data);
				console.log("===============================vuplex message :")
				console.log(json.user_uuid)
				setUserUuid(json);
				props.setShowingTabNum(json.modelType);
				props.setUserUuid(json);
			});
		}
	}, []);

	useEffect(() => {
		let temp = fileListSaved?.slice();
		temp = temp?.filter(c => (c.fileName + " " + c.fileType).toLowerCase().match(props.searchText.toLowerCase()));
		setFileList(temp)
	}, [props.searchText, fileListSaved])
	useEffect(() => {
		let temp = props.tapNumber === 0 ? fileListSaved?.slice() : fileListSaved?.slice().filter(c => c.tab === props.tapNumber);
		setFileListSorted(temp)
	}, [fileList, props.tapNumber])
	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: tempType[]) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		},
		hideSelectAll: true,
		columnWidth: 0,
		renderCell: () => ""
	};
	const selectCard = (fileObj: tempType) => {
		props.setFileObj(fileObj);
		setIndexCard(fileObj.fileUuid);
		props.setSelectedPage(1)
	}
	const unSelectCard = (fileObj: tempType) => {
		props.setFileObj(null);
		setIndexCard(-1);
		props.setSelectedPage(-1)
	}
	useEffect(() => {
		console.log(props.fileObj)
	}, [props.fileObj])
	return (
		<>
			{fileListSorted.length > 0 ? props.showingType === 0 ?
				fileListSorted
					.map((file) => {
						return (
							<div className={`${`cardLayout2 cardLayoutG`}`} key={file.fileUuid} onClick={() => { selectCard(file) }}>
								<div className={`cardImageLayout`}>
									<div className={`cardImageG`}>
										<img className={`cardImageG`}
											src={`${file.fileSrc}`}
											placeholder={loadingImg}
										/>
									</div>
									{""}
								</div>
								<div className={`cardTitle`}>{file.fileName + " " + file.fileType}</div>
								<div className={`cardCap`}>{file.price?.toLocaleString('ko-KR')}원</div>
							</div>
						)
					})
				: <Table columns={columns} dataSource={fileListSorted} rowSelection={rowSelection}
					showSorterTooltip={false}
					onRow={(record, index) => {
						return {
							onClick: () => selectCard(record)
						}
					}}
					rowKey={(render) => render.fileUuid} className={`${`tableLayout`} tableLayout tableLayout2`} pagination={{ pageSize: fileListSorted.length, hideOnSinglePage: true }} />
				: <div key={0} className={`emptyCardLayout`} ><FileManageEmptySearchTemp /></div>}
		</>
	)
}


const ProductDetailTab = (props:any) => {
	let {fileObj : {tab, fileUuid, fileName, fileType, fileDetail, fileSrc, fileSrc1, fileSrc2, fileSrc3, fileSrc4, fileTypeDesc, price}}= props;

	return (
		<div>
			<div className="pdt_topWrapper">
				<div className="pdt_topCon1">구매전 필독사항</div>
				<div className="pdt_topCon2">
					<div>상품은 구매 제한 이내로 구매할 수 있습니다.</div>
					<div>재고 확인으로 인한 발송 지연이 발생할 수 있습니다.</div>
				</div>
			</div>
			<div className="galleryFlexC" style={{ alignItems:"center", paddingBottom:"80px" }}>
				<div className="pdt_bodyTitle1">{fileName} {fileType.split("-")[0]}</div>
				<div className="pdt_bodyTitle2">오경환 작가 전시 연계로 제작된 기념 굿즈</div>
				<div className="pdt_bodyTitle3">{fileName}{fileTypeDesc}</div>
				<img className="pdt_fileSrc1" src={fileSrc1}/>
				<div className="pdt_fileSrc1Desc">오경환, {'<'}{fileName}{'>'}</div>
				<img className="pdt_fileSrc2" src={fileSrc2}/>
				<img className="pdt_fileSrc2" src={fileSrc3}/>
				<img className="pdt_fileSrc2 pdt_fileSrc4" src={fileSrc4}/>
				{fileDetail.length>0 && fileDetail.map((c:string,i:number)=>{
					return(
						<div className="fileDetail" key={i}>{c}</div>)
				})

				}
			</div>
		</div>
	)
}





const OrderDetailTab = (props:any) => {
	const panelStyle: React.CSSProperties = {
		marginBottom: 24,
		// background: token.colorFillAlter,
		// borderRadius: token.borderRadiusLG,
		border: 'none',
	};
	return (
		<Collapse
			bordered={false}
			className="galleryColl"
			// activeKey={[1,2,3]}
			expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : -90} />}>
				<Collapse.Panel key={1} header="작가 정보">
				<div className="collWrapper">
					<div>작가 이름</div>
					<div>오경환</div>
				</div>
				</Collapse.Panel>
				<Collapse.Panel key={2} header="배송 정보">
				<div className="collWrapper">
					<div>배송 방법</div>
					<div>일반 택배</div>
				</div>
				<div className="collWrapper">
					<div>배송지 변경</div>
					<div>주문 초기에는 구매내역 상세 페이지에서 배송지를 변경할 수 있습니다.<br/>
					​이후에는 관리자에게 문의해주세요.</div>
				</div>
				</Collapse.Panel>
				<Collapse.Panel key={3} header="교환/환불 정보">
				<div className="collWrapper">
					<div>고객 부담</div>
					<div>단순변심, 착오 등 고객 사유</div>
				</div>
				<div className="collWrapper">
					<div>작가 부담</div>
					<div>상품 불량 등 작가 사유</div>
				</div>
				<div className="collWrapper">
					<div>반품 배송비</div>
					<div>{deliveryFee.toLocaleString()}원</div>
				</div>
				<div className="collWrapper">
					<div>보내실 곳</div>
					<div>04781 서울특별시 성동구 성수일로4길 25(성수동 2가)​ </div>
				</div>
				</Collapse.Panel>
		</Collapse>
		)
}


const ProductDetailPage = (props: any) => {
	let { fileObj, setSelectedPrice, selectedPrice, count, setCount } = props;
	const onCountHandler = (type: string) => {
		if (type === 'plus' && count < 4) {
			setCount(count + 1)
		} else if (type === 'minus' && count > 1) {
			setCount(count - 1)
		}
	}
	useEffect(() => {
		setSelectedPrice((fileObj.price * count) + deliveryFee)
	}, [count])
	const onChange = (key: string) => {
		console.log(key); 
	};
	return (
		<div className="orderDetailWrapper">
			<div className="galleryFlex" style={{ justifyContent:"space-between" }}>
				<img src={fileObj.fileSrc} width="560px" />
				<div className="galleryFlexC" style={{ gap: "25px", width: "45%" }}>
					<div className="pdHeaderTitle">{fileObj.fileName} {fileObj.fileType}</div>
					<div className="galleryFlex">
						<div className="pdHeaderL">판매가</div>
						<div className="pdHeaderGray">{fileObj.price?.toLocaleString()}원</div>
					</div>
					<div className="galleryFlex">
						<div className="pdHeaderL">구매제한</div>
						<div className="pdHeaderGray">옵션당 최소 4개</div>
					</div>
					<div className="galleryFlex">
						<div className="pdHeaderL">배송비</div>
						<div className="pdHeaderGray" >{deliveryFee.toLocaleString()}원<br />택배</div>
					</div>
					<div className="galleryFlex">
						<div className="pdHeaderL">수량</div>
						<div className="galleryFlex">
							<div className={`pdHeadercount pdHeaderMinus ${count === 1 ? "disabled" : ""}`} onClick={() => { onCountHandler("minus") }}><MinusOutlined /></div>
							<div className="pdHeaderGray pdHeadercount">{count}</div>
							<div className={`pdHeadercount pdHeaderPlus ${count === 4 ? "disabled" : ""}`} onClick={() => { onCountHandler("plus") }}><PlusOutlined /></div>
						</div>
					</div>
					<div className="pdHeaderline"></div>
					<div className="galleryFlex" style={{ justifyContent: "space-between" }}>
						<div className="pdHeaderL">총 상품 금액</div>
						<div className="pdHeaderTotalPrice">{selectedPrice.toLocaleString()}원</div>
					</div>
				</div>
			</div>
			<Tabs defaultActiveKey="1" onChange={onChange} className="galleryTab">
				<Tabs.TabPane key= '1' tab='상품 상세'>
					<ProductDetailTab fileObj={fileObj} />
				</Tabs.TabPane>
				<Tabs.TabPane key= '2' tab='구매 안내'>
					<OrderDetailTab fileObj={fileObj} />
				</Tabs.TabPane>
			</Tabs>
		</div>)
}

const OrderDetailPage = (props: any) => {
	let { fileObj, selectedPrice, count, setPayPossible } = props;
	const [option, setOption] = useState(0);
	const [address, setAddress] = useState("");
	const [phone, setPhone] = useState("");
	useEffect(()=>{
		if(address.length>0 && phone.length>0){
			setPayPossible(true)
		} else {
			setPayPossible(false)
		}
	}, [address, phone])
	return (
		<div className="orderDetailWrapper">
			<div className="orderDetailContainer">
				<div className="odpHeader">배송지 정보</div>
				<div className="collWrapper">
					<div>주문자명</div>
					<div>홍길동</div>
				</div>
				<div className="collWrapper" style={{alignItems:'center'}}>
					<div>받는 주소</div>
					<div><Input style={{ width : '320px', fontSize:"14px" }} size="large" value={address} onChange={(e)=>setAddress(e.target.value)} placeholder="주소를 입력하세요."/></div>
				</div>
				<div className="collWrapper" style={{alignItems:'center'}}>
					<div>전화번호</div>
					<div><Input style={{ width : '320px', fontSize:"14px" }} size="large" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder="숫자만 입력하세요."/></div>
				</div>
				<div className="collWrapper" >
					<div>배송 요청사항</div>
					<div className="galleryFlexC" style={{gap:'5px'}}>
						<Select value={option} onChange={setOption}
						style={{ width : '320px', fontSize:"16px" }} size="large">
							<Option value={10}>문앞에 놔주세요</Option>
							<Option value={20}>경비실에 맡겨주세요</Option>
							<Option value={30}>택배함에 넣어주세요</Option>
							<Option value={40}>배송전에 연락주세요</Option>
							<Option value={0}>직접입력</Option>
						</Select>
					</div>
				</div>
				<div className="collWrapper" style={{marginBottom:"80px", paddingLeft:"120px"}}>
					{option === 0 && <TextArea
						 style={{ width : '320px', resize:"none" }}
						rows={5} placeholder="여기에 입력해주세요." showCount maxLength={50}
						 />}
				</div>
				<div className="odpHeader">주문 상품 1개</div>
				<div  className="odpOrderlist galleryFlex" style={{alignItems:"center", gap:'25px'}}>
					<img src={fileObj.fileSrc} width="105px" style={{borderRadius:"10px"}}/>
					<div className="galleryFlexC" style={{gap:'5px'}}>
						<div>{fileObj.fileName} {fileObj.fileType}</div>
						<div>{count}개</div>
						<div>{selectedPrice.toLocaleString()}원</div>
					</div>
				</div>
				<div className="odpHeader">결제 수단</div>
				<div  className="odpCardWrapper galleryFlexC" style={{gap:'5px'}}>
					<div>카드 등록</div>
					<div>등록하기</div>
				</div>
				<div className="odpCardDesc galleryFlex" style={{justifyContent:"space-between"}}>
					<div>주문 내용을 확인했으며 결제에 동의합니다.</div>
					<div>자세히</div>
				</div>
				<div className="odpCardDesc galleryFlex" style={{justifyContent:"space-between"}}>
					<div>회원님의 개인정보는 안전하게 관리됩니다 .</div>
					<div>자세히</div>
				</div>
				<div className="odpCardDesc" style={{marginBottom:"80px"}}>
					<div>MXspace는 판매 중개자로, 배송 상품의 상품/상품정보/거래 등에 대한 책임은<br/>MXspace가 아닌 판매자에게 있습니다.</div>
					<div></div>
				</div>
			</div>
		</div>)
}