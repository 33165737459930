import styled from "styled-components";
import { Modal } from 'antd';
import { LegacyButtonType } from "antd/lib/button/button";
import '../../utils/utils.css';
import { ReactNode } from "react";
import LANG from "./i18n";

export const BodyLayout = styled.div`
    display: grid;
    grid-template-rows: 146px 34px auto 194px;
    margin-left: 150px;
    margin-right: 150px;
    background-color: #fff;
    text-align: center;
    align-items: center;
    @media screen and (max-width: 1160px) {
        margin-left: 60px;
        margin-right: 60px;
    }
`
export const BodyHeaderLayout = styled.div`
    display: grid;
    grid-template-columns: 30% 40% 30%;
`;
export const BodyMainLayout = styled.div`
    display: flex;
    flex-flow: wrap;
    padding: 22.5px 4px 22.5px 20px;
    height: 490px;
    background-color: #F5F5F5;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: gray;
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    @media screen and (max-width: 1160px) {
        height: 1162px;
        padding: 22.5px 4px 22.5px 20px;
    }
`;
export const BodyFooterLayout = styled.div`
    display: grid;
    grid-template-rows: 60px 90.7px;
    grid-template-columns: 30% 40% 30%;
`;

export const BodyTapLayout = styled.div`
    align-self: end;
`;

export const TitleLayout = styled.div`
    font-size: 25px;
    font-weight: 500;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

export const CardLayout = styled.div`
    display: grid;
    grid-template-columns: 70px calc(100% - 70px);
    padding: 10px;
    @media screen and (max-width: 1160px) {
        grid-template-columns: 70px calc(100% - 70px);
    }
`;

export const CartContentLayout = styled.div`
    display: -webkit-box;
    height: 66px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const FooterMessageLayout = styled.div`
    font-size: 18px;
    // padding-bottom: 26px;
    margin-left: -100%;
    margin-right: -100%;
    text-align: center;
    line-height: 22px;
    font-weight: 400;
`;

export const cardStyle = {background: "white"};
export const cardSelect = {background: "#0047ed80"};
export const nomalIcons = {fontSize:"35px", marginLeft: "20px"} 
export const activeIcons = {color: "#2264FF", fontSize:"35px", marginLeft: "20px"} 
export const disabledIcons = {color: "#8F8F8F", fontSize:"35px", marginLeft: "20px"} 
export const initUser = {"user_uuid":"", "isModel":false, "modelType": 0}


export interface fileObject {
    length: number;
    map(arg0: (item: any) => JSX.Element): import("react").ReactNode;
    uid: "", 
    owneruid?: "",
    filelink?:"", 
    filename: "", 
    fileextern: "", 
    contentstype?: "", 
    filehash?: "", 
    filesize?: "",
    filepath?: "",
    filetype?: "", 
    createtime?: ""
}

export interface userObject{
    user_uuid: string, isModel: boolean, modelType: number, lang: string
}
function filterLang (text:string, lang:string="kr"){
    var pattern3 = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
    if(pattern3.test(text) && lang!=="kr"){ 
        console.log(LANG[`${lang}`]["t"][text.replaceAll(" ", "_")])
        return(LANG[`${lang}`]["t"][text.replaceAll(" ", "_")])
    }
    else return text

    
}

export const closeWindow = (e:any) => {
	var customWindow:any = window.open('', '_parent', '');
	customWindow.close();
}

export function  InappModalInfo(
    contentText:string, lang:string='kr', trans:boolean=true
) {
    console.log(contentText)
    Modal.info({
        icon : null,
        className : "inappModal",
        content: <div style={{padding:'10px', whiteSpace:"pre-wrap"}}>{trans ? filterLang(contentText, lang) : contentText}</div>,
        okText: LANG[`${lang}`]["t"]["확인"],
        onOk() {},
        centered:true
    });
}
export function InappModalConfirm(
    content:any, exceFunction:Function, lang:string='kr'
) {
    Modal.confirm({
        icon : null,
        className : "inappModal inappModalConfirm",
        content: content,
        okText: LANG[`${lang}`]["t"]["확인"],
        cancelText: LANG[`${lang}`]["t"]["취소"],
        onOk() {
            exceFunction();
        },
        onCancel() {},
        centered:true
    });
}
export function InappModalConfirm2(
    content:any, exceFunction:Function, cancelFunction:Function, confirmType:LegacyButtonType | undefined, lang:string='kr'
) {
    Modal.confirm({
        icon : null,
        className : "inappModal inappModalConfirm",
        content: content,
        okType: confirmType,
        okText: LANG[`${lang}`]["t"]["확인"],
        cancelText: LANG[`${lang}`]["t"]["취소"],
        onOk() {
            exceFunction();
        },
        onCancel() {
            cancelFunction()
        },
        centered:true
    });
}
export function InappModalConfirmWithTitle(
    title:string, content:any, exceFunction:Function, lang:string='kr'
) {
    Modal.confirm({
        icon : null,
        title : title,
        className : "inappModal inappModalConfirm",
        bodyStyle:{paddingLeft:'20px', paddingRight:'20px'},
        content: content,
        okText: LANG[`${lang}`]["t"]["확인"],
        cancelText: LANG[`${lang}`]["t"]["취소"],
        onOk() {
            exceFunction();
        },
        onCancel() {},
        centered:true,
        transitionName: "",
        maskTransitionName : ""
    });
}