import React, { useEffect } from 'react';
import './style.css';
import stepLogo from './images/step_logo.png';
import space from './images/space.png';
import download_ico from './images/download_ico.png';
import mxspace_logo from './images/mxspace_logo.png';
import manual_download_icon from './images/manual_download_icon.png';
import { useNavigate, useParams } from "react-router";
const Applink = () => {
    const params = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        // params.hasOwnProperty("company")
        if(params.company === "koreaTech"){

        } else {
            navigate('/');
        }
    },[])
    return (
        <div className="applink" style={{ height: '100vh'}}>
           <div className="header">
            <img src={stepLogo} alt="step_logo" />
            </div>
            <section className="manual">
                <div className='manualWrapper'>
                    <p className="manualTitle">한국기술교육대학교 XR 교육훈련</p>
                    <a href={`${process.env.REACT_APP_SERVER_BASE_URL}/api/bundle/applink/${params.company || ''}/MXspaceUser.pdf`} className="manualDownload">
                        <p>사용자 매뉴얼 Ver 1.1</p>
                        <img src={manual_download_icon} alt="download_icon" />
                    </a>
                </div>
                
            </section>
            <section className="mainSection">
                <div className="mainWrapper">
                    <img src={space} alt="YMX space" />
                    <div className="contents">
                        <div>
                            <img className="logo" src={mxspace_logo} alt="MXspace 로고" />
                            <p className="contentTxt1">STEP 교육훈련 XR 기반<br />워크스페이스 플랫폼</p>
                            <a href={`${process.env.REACT_APP_SERVER_BASE_URL}/api/bundle/applink/${params.company || ''}/MXSpaceSetup.exe`} className="download" download="MXSpaceSetup.exe" >
                                <p>다운로드</p>
                                <img src={download_ico} alt=""/>
                            </a>
                        </div>
                        <div>
                            <p className='loginTxt'>*로그인 활성화 불가 시</p>
                            <a className='download2' href={`${process.env.REACT_APP_SERVER_BASE_URL}/api/bundle/applink/VC_redist.x64.exe`} download="VC_redist.x64.exe">
                            Microsoft Visual C++ 재배포 가능 최신 지원 다운로드
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Applink;