import "../../utils/utils.css";
import "./css/LobbyFileManage.css";

import ui_ic_close_blue from './images/ui_btn_delete.png'
import ui_thumbnail_check_box from './images/ui_thumbnail_check_box@2x.png'
import altImage from './images/file_non.png'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import SortingIcon from './images/ui_ic_double_arrow.png';
import { Button, Progress, Modal } from "antd";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { getFileListByExtension, deleteFile, checkFileSharing, uploadFileByGroup, getDownloadURL, getDownloadThumbnailURL } from '../../../_actions/WebviewFileAction';
import { fileObject, userObject, InappModalConfirm, InappModalInfo, closeWindow, InappModalConfirmWithTitle, InappModalConfirm2 } from './FileManageCommon';
import { getReadableFileSize } from '../../utils/utils';
import { FileManageNotFile2 } from "./FileManageNotFile"
import { useParams } from "react-router";
import loadingImg from './images/file_loading.png';
import FileManageEmptySearch from "./FileManageEmptySearch";
import { getOnlyFileName } from "../../../constants/func";
import Table, { ColumnsType } from "antd/lib/table";
import 'moment/locale/ko';
import moment from "moment";
import {FILETYPE, FILEEXTENTION} from "../../../constants/fileType";
import CODE from "../../../constants/code";
import LANG from "./i18n";

const sortingInArr = ["asc", "desc"];
const sortingByArr = ["filename", "filetype", "filesize", "createtime"];
export const defaultLang = "kr"
const None = 0;
const Image = 1 << 0;
const PDF = 1 << 1;
const MP4 = 1 << 2;
const Model = 1 << 3;
// { "user_uuid": "165", "modelType": 0 }
// {"type": "reload"}
export default function FileMange2(props:any) {
	let {isLobby = false} = props; // lobbyFile인 경우 true로 진입
	const [userData, setUserData] = useState<userObject>({ "user_uuid": "", "isModel": false, "modelType": 0, lang: defaultLang }); // user_uuid는 string으로 유지
	const [totalCapacity, setTotalCapacity] = useState(0);
	const [fileObj, setFileObj] = useState<fileObject | null>();
	const [percent, setPercent] = useState(0);
	const [percentMsg, setPercentMsg] = useState("");
	const [searchText, setSearchText] = useState("");
	const [showingTabNum, setShowingTabNum] = useState(10);
	const [reload, setReload] = useState(false); // 파일 처리 감지용

	const [showingType, setShowingType] = useState(0); // 0 - 큰 이미지, 1- 자세히
	const [sortingBy, setSortingBy] = useState(sortingByArr[0]);
	const [sortingIn, setSortingIn] = useState(sortingInArr[0]);
	const [showingModal, setShowingModal] = useState(false); 



	useEffect(() => {
		var metaTags2 = document.createElement("meta");
		metaTags2.setAttribute("http-equiv", "Expires");
		metaTags2.setAttribute("content", "0");
		document.getElementsByTagName('head')[0].insertBefore(metaTags2, document.getElementsByTagName('head')[0].firstChild)
		var metaTags1 = document.createElement("meta");
		metaTags1.setAttribute("http-equiv", "Cache-Control");
		metaTags1.setAttribute("content", "no-cache, no-store, must-revalidate");
		document.getElementsByTagName('head')[0].insertBefore(metaTags1, document.getElementsByTagName('head')[0].firstChild)
	}, []);

	const setTapNumber = (tapNumber: number) => {
		setUserData({ ...userData, "modelType": tapNumber })
	}
	const handleFileDelete = () => {
		const data: fileObject | undefined | null = fileObj;
		if(data == null) return InappModalInfo(LANG[`${userData.lang}`]["t"]["파일이_선택되지_않았습니다."], userData.lang);

		checkFileSharing(data.filepath, data.filelink, data.uid).then((res) => {
			console.log(res.data)
			if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
				InappModalConfirm(LANG[`${userData.lang}`]["t"]["파일을_삭제합니까?"], () => {
					deleteFile(data.filepath, data.filelink).then((res) => {
						if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
							setFileObj(null);
							setReload(!reload);
						} else {
							InappModalInfo(res.data.resultMessage || LANG[`${userData.lang}`]["t"]["잠시_후_시도해주세요."], userData.lang);
						}
					}).catch(err=>{if(err.response){InappModalInfo(err.response.resultMessage|| LANG[`${userData.lang}`]["t"]["잠시_후_시도해주세요."], userData.lang)}})
				}, userData.lang)
			} else if (Number(res.data.resultCode) === Number(CODE.RCV_WRONGINFO)){
				
				InappModalInfo(LANG[`${userData.lang}`]["t"]["해당_파일은"] + res.data.resultMessage +LANG[`${userData.lang}`]["t"]["스페이스_에서_사용중입니다._공유_해제_후_삭제해주세요."], userData.lang, false);
			} else {
				InappModalInfo(res.data.resultMessage || LANG[`${userData.lang}`]["t"]["잠시_후_시도해주세요."], userData.lang);
			}
		}).catch(err=>{if(err.response){InappModalInfo(err.response.resultMessage|| LANG[`${userData.lang}`]["t"]["잠시_후_시도해주세요."], userData.lang)}})
		
	}
	const handleFileUpload = (e: ChangeEvent<HTMLInputElement> | any) => {
		e.preventDefault();
		var files;
		var file;
		if (e.type === "drop") {
			// 드래그 앤 드롭 했을때
			files = e.dataTransfer!.files
			file = e.dataTransfer!.files[0];
			
		} else {
			// "파일 첨부" 버튼을 눌러서 이미지를 선택했을때
			files = e.target.files
			file = e.target.files[0];
		}
		if (files.length > 1) return InappModalInfo(LANG[`${userData.lang}`]["t"]["파일을_하나씩_업로드_해주세요."], userData.lang);
		const formData: any = new FormData();
		var filename = files !== null ? file.name : '';
		var extension = filename.length > 3 ? filename.slice(-4) : '';
		if ((!isLobby && userData.modelType !== Model) && FILEEXTENTION["fileUpload_2d"].indexOf(extension.toUpperCase()) < 0) return InappModalInfo(LANG[`${userData.lang}`]["t"]["업로드_가능한_파일_형식이_아닙니다."], userData.lang);
		if ((!isLobby && userData.modelType === Model) && FILEEXTENTION["fileUpload_3d"].indexOf(extension.toUpperCase()) < 0) return InappModalInfo(LANG[`${userData.lang}`]["t"]["업로드_가능한_파일_형식이_아닙니다."], userData.lang);
		if (isLobby && [...FILEEXTENTION["fileUpload_2d"], ...FILEEXTENTION["fileUpload_3d"]].indexOf(extension.toUpperCase()) < 0) return InappModalInfo(LANG[`${userData.lang}`]["t"]["업로드_가능한_파일_형식이_아닙니다."], userData.lang);
		formData.append("file", file);
		formData.append("user_uuid", userData.user_uuid);
		return InappModalConfirm(LANG[`${userData.lang}`]["t"]["파일을_업로드_할까요?"],
		() => {uploadAndProgress(e, formData, extension); e.target.value = ''; }, userData.lang)
	}

	const config = {
		onUploadProgress: (progressEvent: { loaded: number; total: number; }) => {
			setPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total) || 0)
			if (Math.round((progressEvent.loaded * 100) / progressEvent.total) === 100) { setPercentMsg(LANG[`${userData.lang}`]["t"]["썸네일_이미지_생성_중입니다."]) }
		}
	}

	const uploadAndProgress = async (e: any, formData: any, extension:String) => {
		try {
			let groupNum =
			userData.modelType === Model || FILEEXTENTION["fileUpload_3d"].indexOf(extension.toUpperCase()) >-1 ? FILETYPE["3d_Asset_Group"][0]
			: FILEEXTENTION["fileUpload_doc"].indexOf(extension.toUpperCase()) >-1 ? FILETYPE["2d_Doc_Group"][0]
			: FILEEXTENTION["fileUpload_video"].indexOf(extension.toUpperCase()) >-1 ? FILETYPE["2d_Video_Group"][0]
			: FILEEXTENTION["fileUpload_image"].indexOf(extension.toUpperCase()) >-1 ? FILETYPE["2d_Image_Group"][0]
			: FILETYPE["3d_Asset_Group"][0];

			await uploadFileByGroup(formData, groupNum , Number(userData.user_uuid), config)
				.then((res: any) => {
					if (Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
						InappModalInfo(res.data.resultMessage, userData.lang)
					} else {
						InappModalInfo(res.data.resultMessage || LANG[`${userData.lang}`]["t"]["잠시_후_시도해주세요."], userData.lang);
					}
				}).catch(err=>{if(err.response){InappModalInfo(err.response.resultMessage|| LANG[`${userData.lang}`]["t"]["잠시_후_시도해주세요."], userData.lang)}})
			setReload(!reload);
			e.target.value = '';
			setTimeout(() => { setPercent(0); setPercentMsg(""); }, 500);
		} catch (error: any) {
			setPercent(0); setPercentMsg("");
			e.target.value = '';
		}
	}

	const handleSearch = (e: any) => {
		setSearchText(e.target.value);
	}

	const handleCleanSearch = () => {
		setSearchText("");
	}

	useEffect(() => { // 파일 처리되면 input:file은 빈 값으로 처리
		document.getElementById("fileUploadInput")!.setAttribute("value", "");
	}, [reload])

	const shareHandler = (item?: any) => {
		InappModalConfirmWithTitle( LANG[`${userData.lang}`]["t"]["선택한_파일을_공유합니다."],
		(<div>
			{fileObj && <p>{fileObj?.filename}</p>}
			{item && <p>{item?.filename}</p>}
		</div>)
		, ()=>{
			if(fileObj) console.log({type:"fid", id : `${fileObj.uid}`, downloadLink : getDownloadURL(fileObj.filepath, fileObj.filelink)})
			if (window.vuplex) {
				try {
					if (fileObj) {
						window.vuplex.postMessage({type:"fid", id : `${fileObj.uid}`, downloadLink : getDownloadURL(fileObj.filepath, fileObj.filelink)});
					}
					else if (item) window.vuplex.postMessage(item);
				} catch (error) {
					const message: any = fileObj?.filename;
					webViewError(message);
					console.error(LANG[`${userData.lang}`]["t"]["웹뷰가_없거나,_웹뷰_처리에서_문제가_발생했습니다."]+" : "+ error);
				}
			} else {
				console.log("vuplex NOT CONNECTED")
			}
		}, userData.lang)
	}
	const webViewError = (filename: string) => {
		Modal.error({
			title: LANG[`${userData.lang}`]["t"]["웹뷰가_없거나,_웹뷰_처리에서_문제가_발생했습니다."],
			content: filename,
		});
	}
	const openFileInput = () => {
		document.getElementById("fileUploadInput")!.click()
	}
	//드래그앤드롭 구현
	const dragRef = useRef<HTMLDivElement | null>(null);
	const [isDragging, setIsDragging] = useState<boolean>(false);

	const handleDragIn = (e: DragEvent): void => {
		e.preventDefault();
		e.stopPropagation();
	}

	const handleDragOut = (e: DragEvent): void => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
	}

	const handleDragOver = (e: DragEvent): void => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer!.files) {
			setIsDragging(true);
		}
	}
	const handleDrop = (e: DragEvent): void => {
		e.preventDefault();
		e.stopPropagation();
		handleFileUpload(e)
		setIsDragging(false);
	};
	const preventDrop = (e: DragEvent): void => {
		e.stopPropagation();
		e.preventDefault();
		e.dataTransfer!.effectAllowed = 'none';
		e.dataTransfer!.dropEffect = 'none';
	};
	const initDragEvents = useCallback((): void => {// (마운트 될때)  
		window.addEventListener('dragover', preventDrop, false);
		window.addEventListener('drop', preventDrop, false)
		if (dragRef.current !== null) {
			dragRef.current.addEventListener("dragenter", handleDragIn);
			dragRef.current.addEventListener("dragleave", handleDragOut);
			dragRef.current.addEventListener("dragover", handleDragOver);
			dragRef.current.addEventListener("drop", handleDrop);
		}
	}, [handleDragIn, handleDragOut, handleDragOver, handleDrop, userData]);

	const resetDragEvents = useCallback((): void => {// (언마운트 될때)
		window.removeEventListener('dragover', preventDrop, false);
		window.removeEventListener('drop', preventDrop, false)
		if (dragRef.current !== null) {
			dragRef.current.removeEventListener("dragenter", handleDragIn);
			dragRef.current.removeEventListener("dragleave", handleDragOut);
			dragRef.current.removeEventListener("dragover", handleDragOver);
			dragRef.current.removeEventListener("drop", handleDrop);
		}
	}, [handleDragIn, handleDragOut, handleDragOver, handleDrop, userData]);

	useEffect(() => {
		if (Number(userData.user_uuid) > 0) initDragEvents();
		return () => resetDragEvents();
	}, [userData]);

	useEffect(() => {
		if (showingType === 0 && sortingBy === sortingByArr[3]) {
			setSortingBy(sortingByArr[0]);
		}
	}, [showingType]);
	const onChangeSortingByHandler = (e: any, sortingByNum: number) => {
		e.stopPropagation();
		setSortingBy(sortingByArr[sortingByNum]);
		setShowingModal(false);
	}
	const onChangeSortingInHandler = (e: any, sortingInNum: number) => {
		e.stopPropagation();
		setSortingIn(sortingInArr[sortingInNum]);
		setShowingModal(false);
	}
	const onClickModalMaskHandler = (e: any) => {
		e.stopPropagation();
		setShowingModal(false);
	}
	useEffect(() => {
	}, [sortingBy, sortingIn])
	return (
		<div className={"tempAroundBorder"}>
			<div className={"fullLayout"}>
				<div className={percent > 0 ? "onProgress" : "offProgress"}>
					<div className={"onProgressText"}>{percent}%</div>
					<div className={"onProgressLayout"}>
						<Progress strokeColor={{ '0%': '#662DB7', '100%': '#00A9FF' }} status='active' percent={percent} showInfo={false} />
					</div>
					<div className={"onProgressText2"}>{percentMsg}</div>
				</div>
				<div className={"categoryLayout"}>
					<div className={"categoryContent"}>
						<TabComponent tapNumber={userData.modelType} setTapNumber={setTapNumber} showingTabNum={showingTabNum} />
					</div>
					<div className={"categoryFooter"}>
						<CapacityComponent totalCapacity={totalCapacity} userData={userData} />
					</div>
				</div>
				<div className={"mainLayout"}>
					<div className={"headerLayout"}>
						<div className={"searchLayout"}>
							<div className={"searchIconLayout"}><SearchIcon /></div>
							<input className={"searchText"} type="text" placeholder={LANG[`${userData.lang}`]["t"]["파일을_검색하세요"]} onChange={e => { handleSearch(e); }} value={searchText || ""} />
							<div className={"searchIconLayout"} >
								<div onClick={handleCleanSearch}>
									<img alt='ui_ic_close_blue' id={"ui_ic_close_blue"} className={"searchIconLayout"} src={ui_ic_close_blue} />
								</div>
							</div>
						</div>
						<div className={"headerButtons"}>
							<input type="file" id="fileUploadInput" onChange={e => { handleFileUpload(e) }} hidden />
							<CloseIcon className={"headerIconLayout"}
								onClick={() => {
									if (fileObj) { 
										InappModalConfirm2(<div>{LANG[`${userData.lang}`]["t"]["팝업_창을_닫습니까?"]}</div>, ()=>closeWindow(""), () => { console.log("cancel closing")}, "primary", userData.lang)
									}
									else { closeWindow("") }
								}}
							/>
							<DriveFolderUploadIcon className={"headerIconLayout"}
								onClick={() => openFileInput()}
							/>
							<DeleteOutlineIcon
								className={"headerIconLayout"}
								onClick={handleFileDelete}
							/>
						</div>
					</div>
					<div className={"sortLayout"}>
						<div className={"sortLayoutL"}>
							<div className={showingType ? "sortByLayout" : "sortByLayoutClicked"} onClick={() => { setShowingType(0) }}>
								<img className={showingType ? "sortBy1" : "sortBy1Clicked"} alt="sortBy1" />
								<div className={showingType ? "sortByText" : "sortByTextClicked"}>{LANG[`${userData.lang}`]["t"]["이미지"]}</div>
							</div>
							<div className={!showingType ? "sortByLayout" : "sortByLayoutClicked"} onClick={() => { setShowingType(1) }}>
								<img className={showingType ? "sortBy2" : "sortBy2Clicked"} alt="sortBy2" />
								<div className={!showingType ? "sortByText" : "sortByTextClicked"}>{LANG[`${userData.lang}`]["t"]["자세히"]}</div>
							</div>
						</div>
						<div className={"sortInLayout"} onClick={() => { setShowingModal(true) }}>
							<div className={"sortInText"}>{LANG[`${userData.lang}`]["t"]["정렬"]}<img className={"sortInIcon"} src={SortingIcon} alt="sortIn" /></div>
							<div className={showingModal ? "sortModalMask" : "sortModalMaskClosed"} onClick={(e) => { onClickModalMaskHandler(e) }}></div>
							<div className={showingModal ? "sortModal" : "sortModalClosed"}>
								<div className={sortingBy === sortingByArr[0] ? `${"sortModalMenuChecked"} ${"sortModalMenu"} ` : "sortModalMenu"} onClick={(e) => onChangeSortingByHandler(e, 0)}>{LANG[`${userData.lang}`]["t"]["이름"]}</div>
								<div className={sortingBy === sortingByArr[1] ? `${"sortModalMenuChecked"} ${"sortModalMenu"} ` : "sortModalMenu"} onClick={(e) => onChangeSortingByHandler(e, 1)}>{LANG[`${userData.lang}`]["t"]["확장자"]}</div>
								<div className={sortingBy === sortingByArr[2] ? `${"sortModalMenuChecked"} ${"sortModalMenu"} ` : "sortModalMenu"} onClick={(e) => onChangeSortingByHandler(e, 2)}>{LANG[`${userData.lang}`]["t"]["용량"]}</div>
								{showingType === 1 && <div className={sortingBy === sortingByArr[3] ? `${"sortModalMenuChecked"} ${"sortModalMenu"} ` : "sortModalMenu"} onClick={(e) => onChangeSortingByHandler(e, 3)}>{LANG[`${userData.lang}`]["t"]["업로드_시간"]}</div>}
								<div className={sortingIn === sortingInArr[0] ? `${"sortModalMenuChecked"} ${"sortModalMenu"} ${"sortModalBorder"} ` : `${"sortModalMenu"} ${"sortModalBorder"}`} onClick={(e) => onChangeSortingInHandler(e, 0)}>{LANG[`${userData.lang}`]["t"]["오름차순"]}</div>
								<div className={sortingIn === sortingInArr[1] ? `${"sortModalMenuChecked"} ${"sortModalMenu"} ` : "sortModalMenu"} onClick={(e) => onChangeSortingInHandler(e, 1)}>{LANG[`${userData.lang}`]["t"]["내림차순"]}</div>
							</div>
						</div>
					</div>
					<div className={"bodyLayout"} ref={dragRef}>
						<CardListComponent
							searchText={searchText}
							tapNumber={userData.modelType}
							setTotalCapacity={setTotalCapacity}
							setFileObj={setFileObj}
							setTapNumber={setTapNumber}
							setUserData={setUserData}
							setShowingTabNum={setShowingTabNum}
							isLobby={isLobby}
							setReload={setReload}
							reload={reload}
							openFileInput={openFileInput}
							handleFileUpload={handleFileUpload}
							userData={userData}
							sortingIn={sortingIn}
							sortingBy={sortingBy}
							showingType={showingType}
						/>
					</div>
					{isLobby ? 
					<div className={"footerLayout"}>	
						<div className={"footerTitle"}><b>{LANG[`${userData.lang}`]["t"]["지원_확장자"]} : </b>{FILEEXTENTION["fileUpload_2d"].join(", ").replaceAll(".", "")}</div>
						<div className={"footerTitle"}><b>{LANG[`${userData.lang}`]["t"]["3D_지원_확장자"]} : </b>{FILEEXTENTION["fileUpload_3d"].join(", ").replaceAll(".", "")}</div>
					</div>
					: <div className={"footerLayout2"}>	
						{userData.modelType & Image ? <div className={"footerTitle"}><b>{LANG[`${userData.lang}`]["t"]["지원_확장자"]} : </b>{FILEEXTENTION["fileUpload_image"].join(", ").replaceAll(".", "")}</div> 
						: userData.modelType & PDF ? <div className={"footerTitle"}><b>{LANG[`${userData.lang}`]["t"]["지원_확장자"]} : </b>{FILEEXTENTION["fileUpload_doc"].join(", ").replaceAll(".", "")}</div> 
						: userData.modelType & MP4 ? <div className={"footerTitle"}><b>{LANG[`${userData.lang}`]["t"]["지원_확장자"]} : </b>{FILEEXTENTION["fileUpload_video"].join(", ").replaceAll(".", "")}</div> 
						: userData.modelType & Model ? <div className={"footerTitle"}><b>{LANG[`${userData.lang}`]["t"]["3D_지원_확장자"]} : </b>{FILEEXTENTION["fileUpload_3d"].join(", ").replaceAll(".", "")}</div> : null}
						<div className={"footerBtn"}>
							<Button style={{ width: "154px", height: "46px", borderRadius: "30px", fontSize: "16px" }}
								onClick={shareHandler}
								disabled={fileObj ? false : true}
								type="primary"
								className={"footerBtn2"}
							>
								{LANG[`${userData.lang}`]["t"]["확인"]}
							</Button>
						</div>
					</div>}
				</div>
			</div>
		</div>
	)
}

// const TabComponent = (props: any) => {
// 	let { tapNumber, showingTabNum } = props;
// 	return (
// 		<>
// 			{showingTabNum === 0 ?
// 				<div>
// 					<div className={"categoryItem"} style={tapNumber === 0 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(0) }}>ALL</div>
// 					<div className={"categoryItem"} style={tapNumber === 2 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(2) }}>PDF</div>
// 					<div className={"categoryItem"} style={tapNumber === 3 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(3) }}>MP4</div>
// 					<div className={"categoryItem"} style={tapNumber === 4 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(4) }}>IMAGE</div>
// 				</div>
// 				: showingTabNum === 1 ?
// 					<div className={"categoryItem"} style={tapNumber === 1 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(1) }}>3D</div>
// 					: showingTabNum === 2 ?
// 						<div className={"categoryItem"} style={tapNumber === 2 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(2) }}>PDF</div>
// 						: showingTabNum === 3 ?
// 							<div className={"categoryItem"} style={tapNumber === 3 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(3) }}>MP4</div>
// 							: showingTabNum === 4 ?
// 								<div className={"categoryItem"} style={tapNumber === 4 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(4) }}>IMAGE</div>
// 								: showingTabNum === 100 ?
// 								<div>
// 									<div className={"categoryItem"} style={tapNumber === 0 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(0) }}>ALL</div>
// 									<div className={"categoryItem"} style={tapNumber === 2 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(2) }}>PDF</div>
// 									<div className={"categoryItem"} style={tapNumber === 3 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(3) }}>MP4</div>
// 									<div className={"categoryItem"} style={tapNumber === 4 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(4) }}>IMAGE</div>
// 									<div className={"categoryItem"} style={tapNumber === 1 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(1) }}>3D</div>
// 								</div>
// 								: null
// 			}
// 		</>
// 	)
// }

const TabComponent = (props: any) => {
	let { tapNumber, showingTabNum } = props;
	return (
		<>
			{
				<div>
					{showingTabNum === 15 ? <div className={"categoryItem"} style={tapNumber === 15 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(15) }}>ALL</div> : null}
					{showingTabNum & Image ? <div className={"categoryItem"} style={tapNumber === 1 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(1) }}>IMAGE</div> : null}
					{showingTabNum & PDF ? <div className={"categoryItem"} style={tapNumber === 2 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(2) }}>PDF</div> : null}
					{showingTabNum & MP4 ? <div className={"categoryItem"} style={tapNumber === 4 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(4) }}>MP4</div> : null}
					{showingTabNum & Model ? <div className={"categoryItem"} style={tapNumber === 8 ? { color: '#00A9FF' } : {}} onClick={() => { props.setTapNumber(8) }}>3D</div> : null}
				</div>
				
			}
		</>
	)
}

const CapacityComponent = (props: any) => {
	let { totalCapacity, userData } = props;
	totalCapacity = totalCapacity === null ? 0 : totalCapacity;
	let per = (totalCapacity / 1073741824) * 100;
	let cap = Number.parseFloat(per.toFixed(2)); // 소수 둘째 자리까지
	const barColor = totalCapacity > 996147200 ? "#FF0000" : "#00A9FF" // 950MB 초과시 색상변경
	return (
		<>
			<div>{LANG[`${userData.lang}`]["t"]["사용량"]}</div>
			<Progress percent={cap} showInfo={false} strokeColor={barColor} trailColor={'#DBDBDB'} />
			<div><b>{getReadableFileSize(totalCapacity)}</b>/1000MB</div>
		</>
	);
}

const CardListComponent = (props: any) => {
	const [indexCard, setIndexCard] = useState("");
	const [fileList, setFileList] = useState<fileObject[] | null>();
	const [fileListSaved, setFileListSaved] = useState<fileObject[] | null>();
	const [fileListSorted, setFileListSorted] = useState<fileObject[] | null>();
	const [userData, setUserData] = useState<userObject>(props.userData);
	const params = useParams();
	const [reload, _setRefresh] = useState(0);
	const reloadRef = useRef<number>(reload);
	const setRefreshRef = (value:any) => {
		reloadRef.current = value;
		_setRefresh(value);
	}
	const inHandler = () => {
		setRefreshRef(reloadRef.current + 1);
	  };
	const columns: ColumnsType<fileObject> = [
		{
			title: LANG[`${userData.lang}`]["t"]["이름"],
			dataIndex: 'uid',
			// 제작해서 추가한 sorting 기능과 상충되어 제외처리
			// sorter: (a, b) => a.filename.localeCompare(b.filename),
			render: (value, record, index) => {
				let thumbnailUrl = getDownloadThumbnailURL(record.filepath, record.filelink)
				return (
					<div className={"tableLayoutFontLeft"} style={{ display: "flex", alignItems: "center" }}>
						<img className={"cardImageInTable"} src={thumbnailUrl}
							placeholder={loadingImg} onLoad={(e) => onLoadImageChange(e)} onError={(e) => { errorImageChange(e, record.filetype); }} />
						<div className="fileNameInTable">{getOnlyFileName(record.filename)}</div>
					</div>
				);
			}
		},
		{
			title: LANG[`${userData.lang}`]["t"]["확장자"],
			dataIndex: 'fileextern',
			// 제작해서 추가한 sorting 기능과 상충되어 제외처리
			// sorter: (a, b) => a.fileextern.localeCompare(b.fileextern),
			render(value, record, index) {
				return (<div className={`${"tableLayoutFontRight"} ${"fileexternCell"}`}>{value}</div>)
			},
		},
		{
			title: LANG[`${userData.lang}`]["t"]["용량"],
			dataIndex: 'filesize',
			// 제작해서 추가한 sorting 기능과 상충되어 제외처리
			// sorter: (a, b) => Number.parseFloat(a.filesize!) - Number.parseFloat(b.filesize!),
			render(value, record, index) {
				return (<div className={`${"tableLayoutFontRight"} ${"filesizeCell"}`}>{getReadableFileSize(Number.parseFloat(value!))}</div>)
			},
		},
		{
			title: LANG[`${userData.lang}`]["t"]["업로드_시간"],
			dataIndex: 'createtime',
			// 제작해서 추가한 sorting 기능과 상충되어 제외처리
			// sorter: (a, b) => new Date(a.createtime!).valueOf() - new Date(b.createtime!).valueOf(),
			render(value, record, index) {
				const dateFormat = 'YY-MM-DD HH:mm:ss';
				const today = moment(value).format(dateFormat);
				return (<div className={`${"tableLayoutFontRight"} ${"createtimeCell"}`}>{today}</div>)
			},
		}
	];
	
	useEffect(() => { // vuplex
		if (window.vuplex) {
			window.vuplex.addEventListener('message', (event: { data: any; }) => {
				let json: any = JSON.parse(event.data);
				console.log("===============================vuplex message :")
				console.log(json)
				if(json.user_uuid){
					if(json.lang == undefined) json.lang = defaultLang
					setUserData(json);
					// props.setTapNumber(json.modelType); 23.07.28 wj setUserData와 중복이라서 주석처리
					if(props.isLobby){ props.setShowingTabNum(15); }
					else{ props.setShowingTabNum(json.modelType); }
					props.setUserData(json);
				} else if (json.type && json.type === "reload"){
					inHandler()
				}
				
			});
		}
	}, []);

	useEffect(() => { // web keyQuery
		if (userData.user_uuid === "" && params.hasOwnProperty("keyQuery")) {
			let json = { "user_uuid": params && params.keyQuery!, "modelType": JSON.parse(params && params.modelType!), "isModel": false, "lang" :params && params.lang || defaultLang };
			setUserData(json);
			// props.setTapNumber(json.modelType); 23.07.28 wj setUserData와 중복이라서 주석처리
			if(props.isLobby)  props.setShowingTabNum(15)
			else  props.setShowingTabNum(json.modelType);
			props.setUserData(json);
		}
	}, []);

	useEffect(() => { // 리스트 호출
		if (Number(userData.user_uuid) > 0){
			let filetype = 
			props.tapNumber === 15 ? props.isLobby ? [...FILETYPE["2d_Image_All"], ...FILETYPE["2d_Video_mp4"], ...FILETYPE["2d_Doc_pdf"], ...FILETYPE["3d_Asset_All"]]  :[...FILETYPE["2d_Image_All"], ...FILETYPE["2d_Video_mp4"], ...FILETYPE["2d_Doc_pdf"]] 
			: props.tapNumber & Image ? FILETYPE["2d_Image_All"]
			: props.tapNumber & PDF ? FILETYPE["2d_Doc_pdf"]
			: props.tapNumber & MP4 ? FILETYPE["2d_Video_mp4"]
			: props.tapNumber & Model ? FILETYPE["3d_Asset_All"]
			:[];
			getFileListByExtension(filetype, Number(userData.user_uuid))
				.then((response) => {
					if (Number(response.data.resultCode) === Number(CODE.RCV_SUCCESS)) {
						let json: fileObject[] = response.data.files;
						setFileList(json);
						setFileListSaved(json);
						props.setTotalCapacity(response.data.fileSize);
					} else {
						InappModalInfo(response.data.resultMessage, userData.lang);
					}
				}).catch(err=>{if(!err.response){InappModalInfo(LANG[`${userData.lang}`]["t"]["서버와의_통신_오류가_발생했습니다."])}else{InappModalInfo(err.response.resultMessage|| LANG[`${userData.lang}`]["t"]["잠시_후_시도해주세요."], userData.lang)}})
		
		}
	}, [props.userData, props.tapNumber, props.reload, reloadRef.current]);

	const selectCard = (fileObj: fileObject) => {
		if (indexCard === fileObj.uid) { // 해제 할 때
			props.setFileObj(null);
			setIndexCard("");
		}
		else { // 선택 할 때
			props.setFileObj(fileObj);
			setIndexCard(fileObj.uid);
		}
	}

	const errorImageChange = (e: SyntheticEvent<HTMLImageElement, Event>, filetype: any) => {
		const target = e.target as HTMLImageElement;
		if (FILETYPE["3d_Asset_All"].indexOf(Number(filetype))>-1) target.src = "/images/file_3d.png"
		else if (FILETYPE["2d_Doc_pdf"].indexOf(Number(filetype))>-1) target.src = "/images/file_pdf.png"
		else if (FILETYPE["2d_Video_mp4"].indexOf(Number(filetype))>-1) target.src = "/images/file_mp4.png"
		else if (FILETYPE["2d_Image_jpeg"].indexOf(Number(filetype))>-1) target.src = "/images/file_jpeg.png"
		else if (FILETYPE["2d_Image_jpg"].indexOf(Number(filetype))>-1) target.src = "/images/file_jpg.png"
		else if (FILETYPE["2d_Image_png"].indexOf(Number(filetype))>-1) target.src = "/images/file_png.png"
		else target.src = altImage;
	}
	const onLoadImageChange = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		const target = e.target as HTMLImageElement;
		target.style.backgroundColor = "#DBDBDB"
	}
	useEffect(() => {
		let temp = fileListSaved?.slice();
		temp = temp?.filter(c => c.filename.toLowerCase().match(props.searchText.toLowerCase()));
		setFileList(temp)
	}, [props.searchText, fileListSaved])
	useEffect(() => {
		let temp = fileListSaved?.slice();
		if (temp?.length !== 0 && temp !== null && temp !== undefined) {
			temp = temp.sort((a: any, b: any) => a.createtime.localeCompare(b.createtime));

			if (props.sortingBy === sortingByArr[0]) { // filename
				temp =
					props.sortingIn === sortingInArr[0] ? temp.sort((a: any, b: any) => a.filename.localeCompare(b.filename))
						: temp.sort((a: any, b: any) => b.filename.localeCompare(a.filename))
			} else if (props.sortingBy === sortingByArr[1]) { // filetype
				temp =
					props.sortingIn === sortingInArr[0] ? temp.sort((a: any, b: any) => a.fileextern.localeCompare(b.fileextern))
						: temp.sort((a: any, b: any) => b.fileextern.localeCompare(a.fileextern))
			} else if (props.sortingBy === sortingByArr[2]) { // filesize
				temp =
					props.sortingIn === sortingInArr[0] ? temp.sort((a: any, b: any) => a.filesize - b.filesize)
						: temp.sort((a: any, b: any) => b.filesize - a.filesize)
			} else if (props.sortingBy === sortingByArr[3]) { // createtime
				temp =
					props.sortingIn === sortingInArr[0] ? temp.sort((a: any, b: any) => a.createtime.localeCompare(b.createtime))
						: temp?.sort((a: any, b: any) => b.createtime.localeCompare(a.createtime))
			};
		}
		setFileListSorted(temp)
	}, [fileList, props.sortingBy, props.sortingIn])
	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: fileObject[]) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		},
		hideSelectAll: true,
		columnWidth: 0,
		renderCell: () => ""
	};
	const setRowClassName = (record: fileObject, index: any) => {
		return record.uid === indexCard ? 'tableChecked' : '';
	}
	return (
		<>
			{(fileListSorted?.length !== 0 && fileListSorted !== null && fileListSorted !== undefined) ?
				// 파일 리스트 있을 때
				fileListSorted.filter(c => props.searchText.length > 0 ? c.filename.toLowerCase().match(props.searchText.toLowerCase()) : c).length > 0 ?
					// 검색어 미 입력 or 검색어 입력 결과가 있는 경우
					props.showingType === 0 ?
						fileListSorted.filter(c => props.searchText.length > 0 ? c.filename.toLowerCase().match(props.searchText.toLowerCase()) : c)?.map((file) => {
							let thumbnailUrl =getDownloadThumbnailURL(file.filepath, file.filelink)
							return (
								<div className={indexCard === file.uid ? `${"cardLayout"} ${"selectCardLayout"}` : `${"cardLayout"}`}
									key={file.uid} onClick={() => { selectCard(file) }} title={file.filename} >
									<div className={"cardImageLayout"}>
										<div className={"cardImage"}>
											<img className={"cardImage"}
												src={thumbnailUrl}
												placeholder={loadingImg}
												onLoad={(e) => onLoadImageChange(e)}
												onError={(e) => { errorImageChange(e, file.filetype) }}
												alt="cardImage"
											/>
										</div>
										{indexCard === file.uid ? <img alt='ui_thumbnail_check_box' src={ui_thumbnail_check_box} className={"cardCheckIcon"} /> : ""}
									</div>
									<div className={"cardTitle"}>{file.filename}</div>
									<div className={"cardCap"}>{getReadableFileSize(Number.parseFloat(file.filesize!))}</div>
								</div>
							)
						})
						: <Table columns={columns} dataSource={fileListSorted} rowSelection={rowSelection}
							showSorterTooltip={false}
							rowClassName={setRowClassName}
							onRow={(record, index) => {
								return {
									onClick: () => selectCard(record)
								}
							}}
							rowKey={(render) => render.uid} className={`${"tableLayout"} tableLayout`} pagination={{ pageSize: fileListSorted.length, hideOnSinglePage: true }} />
					// 검색어 입력 && 검색어 결과가 없는 경우
					: <div key={0} className={"emptyCardLayout"}><FileManageEmptySearch lang={userData.lang} /></div>
			: // 파일 리스트 없을 때
			props.searchText.length > 0 ?
				// 검색어 입력
				<div key={0} className={"emptyCardLayout"}><FileManageEmptySearch lang={userData.lang} /></div>
				// 검색어 미입력
				: <div key={0} className={"noCardLayout"}><FileManageNotFile2 lang={userData.lang} openFileInput={props.openFileInput} /></div>
			}
		</>
	)
} 