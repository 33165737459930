import { useEffect, useState } from 'react';
import { Modal, Input, Button, Upload, Select} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import styled from "styled-components";
import { alertMessage, infoMessage } from "../../utils/utils";
import { checkMapname, getMapIndexlist, uploadMapImage, editMap} from '../../../_actions/MapAction'
import CODE from '../../../constants/code';
import { FILETYPE } from '../../../constants/fileType';
import { MAPTHUMBNAIL_UPLOADPATH_ANDROID, MAPTHUMBNAIL_UPLOADPATH_WINDOWS } from '../../../constants/common';
const { Option } = Select;

const ModalBodyLayout = styled.div`
    display: grid; 
    grid-template-columns: 25% auto; 
    grid-template-rows: 60px 200px 120px 70px;
    align-items: center;
`;
const FlexCenterLayout = styled.div`
    display: flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items: flex-start;
    form{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;   

export default function MapListEditModal(props:any) {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [backupMap, setBackupMap] = useState(props.selectedMap);
    const [map_Name, setMap_Name] = useState(props.selectedMap.mapname);
    const [nameCheck, setNameCheck] = useState(3); // 체크필요없는 중복체크 된 상태 3, 체크 필요 2, 중복아님 1, 중복임 0
    const [thumbnailURL_aos, setThumbnailURL_aos] = useState(props.selectedMap.thumbnail_p);
    const [thumbnailURL_win, setThumbnailURL_win] = useState(props.selectedMap.thumbnail);
    const [map_Index, setMap_Index] = useState(props.selectedMap.mapid);
    const [mapidList, setMapIndexList] =  useState<string[]>([]);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if(nameCheck === 2 ) return alertMessage('MAP 이름 중복 확인이 필요합니다.')
        if(nameCheck === 0 ) return alertMessage('MAP 이름 중복입니다.')
        if(thumbnailURL_aos === "" || thumbnailURL_win==="") return alertMessage('썸네일 등록이 필요합니다.')
        if(map_Index ==='') return alertMessage('MAP index 등록이 필요합니다.')
        if(backupMap.mapid === map_Index
        && backupMap.mapname === map_Name
        && backupMap.thumbnail === thumbnailURL_win
        && backupMap.thumbnail_p === thumbnailURL_aos) {
            handleCancel()
        } else {
            const dataToSubmit = {
                ...backupMap,
                "mapid": map_Index,
                "mapname" : map_Name,
                "thumbnail" : thumbnailURL_win,
                "thumbnail_p" : thumbnailURL_aos,
                "assignChanged" :false
            }
            editMap(dataToSubmit).then((res)=>{
                if(res.data){
                    infoMessage('등록 성공')
                    setIsModalVisible(false);
                    props.closeEditModal();
                }
            }).catch((error)=>{
                alertMessage('error');
                console.error(error)
            });
        }  
    };
    const MapItemToFileInfo = (type:string) => {
        let env = `${process.env.REACT_APP_SERVER_BASE_URL}`
        let fileList :UploadFile<any>[] = type==='none' ? 
        [{
           uid: `-1`,
           name: `${map_Name}`,
           status: 'error'        }]
        : [{
           uid: `-1`,
           name: `${map_Name}`,
           status: type==='none' ? 'error' : 'done',
           url : `${type==='aos' ? env+'/'+thumbnailURL_aos : type==='win' ? env+'/'+thumbnailURL_win : ''}`
       }]
           return fileList
    }
    const handleCancel = () => {
        setIsModalVisible(false);
        props.closeEditModal();
    };

    const onMapNameHandler = (event: { currentTarget: { value: string; }; }) => {
        if(backupMap.mapname === event.currentTarget.value){
            setNameCheck(3)
        } else {
            setNameCheck(2)
        }
        setMap_Name(event.currentTarget.value)
    }
    const getMapIndexArr = () => {
        getMapIndexlist()
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setMapIndexList(res.data.mapIndexlist)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
            
        }).catch((error)=>{
            console.error(error)
        });
    }
    useEffect(() => {
        // getMapIndexArr()
        showModal();
    }, [])
    const checkMapName = () => {
        const dataToSubmit = {
            mapname : map_Name
        }
        if(map_Name ==='') return alertMessage('MAP 이름을 입력하세요.')
        checkMapname(dataToSubmit)
        .then((res)=>{
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setNameCheck(res.data.result ? 1 : 0)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
            
        })
        .catch((err)=>{console.error(err)})
    }
    const [fileListAos, setFileListAos] = useState(null);
    const [fileListWin, setFileListWin] = useState(null);
    const handleFileUpload = (e: any, type : string) => {
        e.preventDefault();
        var file = e.target.files[0];
        var imageType = /image.*/;
        if (file && !file.type.match(imageType)) return;
        if(type ==='aos') setFileListAos(file)   
        if(type ==='win') setFileListWin(file) 
    }
    const uploadAos = (e:any, file:any) => {
        e.preventDefault();
        let formData:any = new FormData(); 
        formData.append("thumbnail", file);
        // androidThumbnail 수정 금지
        uploadMapImage(FILETYPE["map_thumbnail_Group"][0], MAPTHUMBNAIL_UPLOADPATH_ANDROID, formData)
        .then((res) => {
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setThumbnailURL_aos(`${res.data.returnPath}`)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
    }
    const uploadWin = (e:any, file:any) => {
        e.preventDefault();
        let formData:any = new FormData(); 
        formData.append("thumbnail", file);

        uploadMapImage(FILETYPE["map_thumbnail_Group"][0], MAPTHUMBNAIL_UPLOADPATH_WINDOWS, formData)
        .then((res) => {
            if(Number(res.data.resultCode) === Number(CODE.RCV_SUCCESS)){
                setThumbnailURL_win(`${res.data.returnPath}`)
            } else {
                alertMessage(res.data.resultMessage || "잠시 후 다시 시도해주세요.")
            }
        })
    }
    function openFileInput2(type:string) {
        if(type === 'aos'){
            const fileInput:any = document.getElementById("aosBtn2");
            fileInput!.click();
        } else if (type ==='win'){
            const fileInput:any = document.getElementById("winBtn2");
            fileInput!.click();
        }
        
        
    }
    return (
        <>
            <Modal title="기본 룸 Setting" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <Button key="ok" onClick={handleOk}>
                        OK
                    </Button>
                ]}
                >
                
                <ModalBodyLayout>
                    <div style={{height:'60px'}}>MAP 이름:</div>
                    <div>
                        <Input onChange={onMapNameHandler} value={map_Name} style={{width: "73%"}}/>
                        <Button style={{marginLeft: 10}} onClick={checkMapName} disabled={nameCheck===3}>중복확인</Button>
                        {nameCheck===1 ?
                        <div style={{color: "#2196f3", fontWeight: 200, height:'30px'}}>사용 가능한 이름입니다.</div>
                        : nameCheck===0 ?
                        <div style={{color: "#ff0000", fontWeight: 200, height:'30px'}}>이미 사용중인 MAP 이름입니다.</div>
                        : <div style={{height:'30px'}}></div>}
                    </div>
                    <div>MAP 썸네일 :<br/>(AOS)</div>
                    <FlexCenterLayout>
                        <Upload    
                                listType="picture-card"
                                fileList={thumbnailURL_aos ==='' || thumbnailURL_aos === undefined ? MapItemToFileInfo('none') : MapItemToFileInfo('aos')}
                                showUploadList={{showRemoveIcon : false, showPreviewIcon : false}}
                                >
                        </Upload>
                        <form id="formElem" encType="multipart/form-data" onSubmit={(e)=>uploadAos(e, fileListAos)}>
                            <input type="file" name="mapUploadAos" id="mapUploadAos"
                                onChange={e => {handleFileUpload(e, 'aos');}}
                                />
                            <input type='submit' id='aosBtn2' hidden/>
                            <div style={{width:'15%'}}>
                                <Button disabled={fileListAos === null} icon={<UploadOutlined />} onClick={()=>openFileInput2('aos')}></Button>
                            </div>
                        </form>
                    </FlexCenterLayout>
                    <div>MAP 썸네일 :<br/>(Windows)</div>
                    <FlexCenterLayout>
                        <Upload    
                            listType="picture-card"
                            fileList={thumbnailURL_win ===''  || thumbnailURL_win === undefined ? MapItemToFileInfo('none') : MapItemToFileInfo('win')}
                            showUploadList={{showRemoveIcon : false, showPreviewIcon : false}}
                            >
                        </Upload>
                            <form id="formElem" encType="multipart/form-data" onSubmit={(e)=>uploadWin(e, fileListWin)}>
                                <input type="file" name="mapUploadWin" id="mapUploadWin"
                                    onChange={e => {handleFileUpload(e, 'win');}} />
                                <input type='submit' id='winBtn2' hidden/>
                                <div style={{width:'15%'}}>
                                    <Button disabled={fileListWin === null} icon={<UploadOutlined />}onClick={()=>openFileInput2('win')}></Button>
                                </div>
                            </form>
                    </FlexCenterLayout>
                    <div>MAP file:</div>
                    <FlexCenterLayout>
                        <div style={{ width: "75%" }}>
                            <Select value={props.selectedMap.mapid === null ? -1 : props.selectedMap.mapid} style={{ width: '100%' }} placeholder="maps Index(win+aos)" onChange={(value) => setMap_Index(value)}
                                disabled>
                                {/* 
                                disabled되어있어서 우선 mapindex를 불러오는 부분 제외
                                {mapidList.length > 0 && mapidList.map((item: any) => {
                                    return (
                                        <Option key={item} value={item}>
                                            {item}
                                        </Option>
                                    )
                                })} */}
                            </Select>
                        </div>
                    </FlexCenterLayout>
                </ModalBodyLayout>
            </Modal>
        </>
    );
};