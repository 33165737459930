import styled from "styled-components"
import ExclamationCircleOutlined from './images/ui_exclamation_mark.png'
import LANG from "./i18n";
import { InfoCircleOutlined } from "@ant-design/icons";

const BodyEmptySearchLayout = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-content: center;
    font-size: 14px;
    light-height: 24px;
`;

const BodyEmptySearchIcon = styled.div`
    display: flex;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100%;
    background: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const BodyEmptySearchText = styled.div`
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

`;
export default function FileManageEmptySearch(props:any) {
    let {lang} = props;
    return (
        <BodyEmptySearchLayout>
            <BodyEmptySearchIcon>
                <img src={ExclamationCircleOutlined} style={{width:"75px"}} alt="ExclamationCircleOutlined" />
            </BodyEmptySearchIcon>
            <BodyEmptySearchText>
            {LANG[`${lang}`]["t"]["검색결과가_없습니다."]}<br/>{LANG[`${lang}`]["t"]["입력하신_파일명을_확인해주세요."]}
            </BodyEmptySearchText>
        </BodyEmptySearchLayout>
    )
}
export function FileManageEmptySearchTemp() {
    return (
        <BodyEmptySearchLayout>
            <BodyEmptySearchIcon>
                <img src={ExclamationCircleOutlined} style={{width:"75px"}} alt="ExclamationCircleOutlined" />
                {/* <InfoCircleOutlined style={{fontSize:"65px", color:"#dddcdd"}}/> */}
            </BodyEmptySearchIcon>
            <BodyEmptySearchText>
                상품 준비중입니다.
            </BodyEmptySearchText>
        </BodyEmptySearchLayout>
    )
}